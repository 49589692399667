@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper-pagination {
  @apply py-8;
}

.disable {
  @apply pointer-events-none;
}

audio::-webkit-media-controls-panel {
  @apply bg-[#e2e8f0];
}
